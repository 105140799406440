import React from 'react';
import { Provider } from 'react-redux';
import store from './src/state/store';
import './src/styles/global.scss';

import '@fontsource/poppins/700.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/300.css';

import '@fontsource/source-sans-pro/900.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/400.css';

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};
