import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store from '../store'
import { RootState, Thunk, Dispatch } from 'state/store'

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark'
}

interface UIState {
  themeMode: ThemeMode
  language: string
}

const initialState: UIState = {
  themeMode: ThemeMode.LIGHT,
  language: 'en'
}

// Slice
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      const { payload } = action
      state.themeMode = payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.language = payload
    }
  }
})

// Reducers
export default uiSlice.reducer

// Selectors
export const uiSelector = (state: RootState) => state.ui

// Actions
const { setThemeMode, setLanguage } = uiSlice.actions

// Thunks
export const toggleThemeMode = (): Thunk => (dispatch: Dispatch) => {
  const { themeMode } = store.getState().ui
  const mode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT

  dispatch(setThemeMode(mode))
}

export const selectLanguage =
  (language: string): Thunk =>
  (dispatch: Dispatch) => {
    dispatch(setLanguage(language))
  }
